import { COOKIES } from '~/constants'

/**
 * This function is used to determine whether to show the app banner. It checks
 * if the user is not using a webviews and hasn't dismissed the banner yet.
 */
export default function () {
  const { isWebView } = usePlatform()
  const bannerCookie = useCookie(COOKIES.appDownloadBannerDismissed)
  const { isMobileOrTablet } = useDevice()

  const showAppBanner = computed(() => !isWebView.value && !bannerCookie.value && isMobileOrTablet)

  return {
    showAppBanner,
  }
}
